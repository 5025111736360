import React from 'react';
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import Line from "../Line";
import './index.css';
import skills from './skills.json';


const Skills = () => {

    return (
        <div id="skills" className="container">

            <Line icon={faMicrochip} section="Skills" />

            <div className="mt-2">
                <div className="row">
                    {skills.map((s) =>
                                  {return <div className="col-6 col-md-3 d-flex justify-content-center" key={s.desc}>
                                      <div className="card override-skills d-flex align-items-center bg-light border-0 mb-4 w-100">
                                          <img src={s.image} alt={s.desc} className="card-img override-skills p-3" />
                                          <div className="card-body">
                                              <h6 className="card-subtitle text-black-50 mb-0">{s.desc}</h6>
                                          </div>
                                      </div>
                                  </div>})}

                </div>
            </div>
        </div>
    )
}

export default Skills;