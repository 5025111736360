import React, { useEffect, useState } from 'react';
import { Divider } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Line = ({ icon, section }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {/*{width < 992 && <Divider orientation="center"><FontAwesomeIcon icon={icon} /></Divider>}*/}
            {/*{width >= 992 && <Divider orientation="left"><FontAwesomeIcon icon={icon} /></Divider>}*/}
            <Divider orientation="center"><FontAwesomeIcon icon={icon} /></Divider>
        </>
    )
}

export default Line;