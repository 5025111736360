const initialState = {
    english: true
}

export default function langReducer(state = initialState, action) {

    switch (action.type) {
        case "toggle":
            return {
                english: !state.english
            }

        default:
            return state
    }

}