import React, {useState} from 'react';
import {useSelector} from "react-redux";
import Line from "../Line";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import './index.css';

const Contact = () => {

    const language = useSelector((state) => state);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errMsg, setErrMsg] = useState(false);

    const encode = (data) => {
        return Object.keys(data)
            .map(
                (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            )
            .join("&");
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === "" || email === "" || message === "") {
            setErrName(name === "");
            setErrEmail(email === "");
            setErrMsg(message === "")
        } else {
            setErrName(false);
            setErrEmail(false);
            setErrMsg(false);

            fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: encode({"form-name": "contact", name, email, message}),
            })
                .then(() => {
                    setSubmitted(true)
                })
                .catch((error) => alert(error));
        }
    };

    return (
        <div id="contact" className="container mt-4">

            <Line icon={faEnvelope} section="Contact"/>

            <form
                name="contact"
                data-netlify="true"
                onSubmit={handleSubmit}
                className="cc-form"
            >

                <div className="form-group has-danger">
                    <label htmlFor="name">
                        {language.english && 'Name'}
                        {!language.english && 'Nombre'}
                    </label>
                    <input
                        type="text"
                        id="name"
                        className={`form-control ${errName ? 'is-invalid' : ''}`}
                        disabled={submitted}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="form-group has-danger mt-3">
                    <label htmlFor="email" className="">Email</label>
                    <input
                        type="email"
                        id="email"
                        className={`form-control ${errEmail ? 'is-invalid' : ''}`}
                        disabled={submitted}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="form-group has-danger mt-3">
                    <label htmlFor="message">
                        {language.english && 'Message'}
                        {!language.english && 'Mensaje'}
                    </label>
                    <textarea
                        id="message"
                        className={`form-control ${errMsg ? 'is-invalid' : ''}`}
                        disabled={submitted}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                <button
                    id="btns"
                    type="submit"
                    className="btn btn-primary mt-3 w-100"
                    disabled={submitted}
                >
                    {language.english && !submitted && 'Submit'}
                    {language.english && submitted && 'Submitted'}
                    {!language.english && !submitted && 'Enviar'}
                    {!language.english && submitted && 'Enviado'}
                </button>

            </form>
        </div>
    )
}

export default Contact;