import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Link} from 'react-scroll';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import ReactCountryFlag from "react-country-flag";
import {contactOffset, scrollDuration} from "../../utils";
import './index.css';

const Navbar = () => {

    const [menu, setMenu] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [section, setSection] = useState("");
    const [contact, setContact] = useState(false);

    const language = useSelector((state) => state);
    const dispatch = useDispatch();

    const toggleMenu = () => {
        setMenu(!menu);
    }

    const handleDispatch = () => {
        dispatch({type: "toggle"})

        switch (section) {
            case 'PROJECTS':
                setSection('PROYECTOS');
                break;

            case 'SKILLS':
                setSection('TECNOLOGIAS');
                break;

            case 'CONTACT':
                setSection('CONTACTO');
                break;

            default:
                setSection("");
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-primary sticky-top">
            <div className="container">
                <Link
                    className="navbar-brand"
                    to="about"
                    onSetActive={() => setSection("")}
                    onClick={() => setMenu(false)}
                    smooth={true}
                    spy={true}
                    offset={-300}
                    duration={scrollDuration()}
                >
                    Juan Ceruli
                </Link>

                {/*<a className="navbar-brand" href="https://www.juanceruli.com">Juan Ceruli</a>*/}

                {
                    width < 768 &&
                    <ul className="navbar-nav me-auto">
                        <li className="text-white-50">{!menu && section}</li>
                    </ul>
                }

                <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                    <span className="navbar-toggler-icon fa-xs"/>
                </button>

                <div className={`collapse navbar-collapse ${menu ? 'show' : ''} `}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClass="active"
                                onSetActive={() => setSection(language.english ? 'PROJECTS' : 'PROYECTOS')}
                                onSetInactive={() => setSection("")}
                                to="projects"
                                smooth={true}
                                spy={true}
                                offset={width >= 768 ? -75 : -290}
                                onClick={toggleMenu}
                                duration={scrollDuration()}
                            >
                                {language.english && 'PROJECTS'}
                                {!language.english && 'PROYECTOS'}
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClass={!contact ? 'active' : 'text-white-50'}
                                onSetActive={() => setSection(language.english ? 'SKILLS' : 'TECNOLOGIAS')}
                                to="skills"
                                smooth={true}
                                spy={true}
                                offset={width >= 768 ? -75 : -290}
                                onClick={toggleMenu}
                                duration={scrollDuration()}
                            >
                                {language.english && 'SKILLS'}
                                {!language.english && 'TECNOLOGIAS'}
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClass="active"
                                onSetActive={() => {
                                    setContact(true);
                                    setSection(language.english ? 'CONTACT' : 'CONTACTO');
                                }}
                                onSetInactive={() => setContact(false)}
                                to="contact"
                                smooth={true}
                                spy={true}
                                offset={contactOffset(width)}
                                onClick={toggleMenu}
                                duration={scrollDuration()}
                            >
                                {language.english && 'CONTACT'}
                                {!language.english && 'CONTACTO'}
                            </Link>
                        </li>
                    </ul>

                    <ul className="navbar-nav ms-auto">

                        <li className="nav-item">
                            {width >= 768 &&
                             <a className="nav-link" href="https://www.linkedin.com/in/juan-ceruli/"
                                target="_blank">
                                 <FontAwesomeIcon icon={faLinkedin} className="fa-lg"/>
                             </a>}

                            {width < 768 &&
                             <a className="nav-link" href="https://www.linkedin.com/in/juan-ceruli/"
                                target="_blank" onClick={toggleMenu}>
                                 LINKEDIN
                             </a>}
                        </li>

                        <li className="nav-item">
                            {width >= 768 &&
                             <a className="nav-link" href="https://github.com/cerulij"
                                target="_blank">
                                 <FontAwesomeIcon icon={faGithub} className="fa-lg"/>
                             </a>}

                            {width < 768 &&
                             <a className="nav-link" href="https://github.com/cerulij"
                                target="_blank" onClick={toggleMenu}>
                                 GITHUB
                             </a>}
                        </li>

                        <li className="nav-link override-flag d-flex align-items-center"
                            onClick={handleDispatch}>
                            {!language.english &&
                             <>
                                 <ReactCountryFlag
                                     countryCode="US"
                                     svg
                                     style={{
                                         width: '1.3em',
                                         height: '1.3em'
                                     }}
                                     title="US"
                                 />
                                 <span className="ms-1 fw-bold">EN</span>
                             </>
                            }

                            {language.english &&
                             <>
                                 <ReactCountryFlag
                                     countryCode="ES"
                                     svg
                                     style={{
                                         width: '1.3em',
                                         height: '1.3em'
                                     }}
                                     title="ES"
                                 />
                                 <span className="ms-1 fw-bold">ES</span>
                             </>
                            }

                        </li>

                    </ul>
                </div>

            </div>
        </nav>
    )

}

export default Navbar;