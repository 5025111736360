import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-scroll";
import {contactOffset, scrollDuration} from "../../utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faCode, faMicrochip} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons';
import './index.css';

const About = () => {

    const language = useSelector((state) => state);

    const [width, setWidth] = useState(window.innerWidth);

    const goTo = (where) => {
        if (where === 'linkedin') {
            window.open('https://www.linkedin.com/in/juan-ceruli/');
        }

        if (where === 'github') {
            window.open('https://github.com/cerulij');
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div id="about" className="container mt-4">

            <div className="cc-screen-text">

                {language.english &&
                 <>
                     <h4>Hello! My name is Juan</h4>
                     {/*<h4>I'm a former financial trader turned software engineer</h4>*/}

                     <div className={`${width >= 992 ? '' : 'd-none'}`}>
                         <p className="mb-0">I worked as a trader for a couple of years at a major
                             bank before transitioning to computer science to pursue a career in
                             electronic trading.</p>
                         <p className="mb-0">I hold a Master's Degree in Computer Science from
                             Northeastern University in Boston, MA; and a Bachelor's in Accounting
                             from the University of Buenos Aires in Argentina.</p>
                         <p className="mb-0">My passions lie in finance, coding, problem-solving,
                             and learning new technologies.</p>
                         <p>Outside of work, I enjoy running, swimming, and playing the guitar.</p>
                     </div>

                     <p className={`${width < 992 ? '' : 'd-none'}`}>
                         I worked as a trader for a couple of years at a major
                         bank before transitioning to computer science to pursue a career in
                         electronic trading.
                         I hold a Master's Degree in Computer Science from Northeastern University
                         in Boston, MA; and a Bachelor's in Accounting from the University of Buenos
                         Aires in Argentina.
                         My passions lie in finance, coding, problem-solving, and learning new
                         technologies.
                         Outside of work, I enjoy running, swimming, and playing the guitar.
                     </p>
                 </>
                }

                {!language.english &&
                 <>
                     <h4>Hola! Me llamo Juan</h4>
                     {/*<h4>Soy un ex operador financiero devenido en ingeniero de software</h4>*/}

                     <div className={`${width >= 992 ? '' : 'd-none'}`}>
                         <p className="mb-0">Trabajé como trader durante un par de años en un
                             importante banco antes de pasar a la informática y continuar en el
                             trading electrónico.</p>
                         <p className="mb-0">Tengo una Maestría en Ciencias de la Computación de
                             Northeastern University en Boston, MA; y soy Contador Público de la
                             Universidad de Buenos Aires en Argentina.</p>
                         <p className="mb-0">Mis pasiones se centran en las finanzas, la
                             programación y el aprendizaje de nuevas tecnologías.</p>
                         <p>Fuera del trabajo, disfruto correr, nadar y tocar la guitarra.</p>
                     </div>

                     <p className={`${width < 992 ? '' : 'd-none'}`}>
                         Trabajé como trader durante un par de años en un importante banco antes de
                         pasar a la informática y continuar en el trading electrónico.
                         Tengo una Maestría en Ciencias de la Computación de Northeastern University
                         en Boston, MA; y soy Contador Público de la Universidad de Buenos Aires en
                         Argentina.
                         Mis pasiones se centran en las finanzas, la programación y el aprendizaje
                         de nuevas tecnologías.
                         Fuera del trabajo, disfruto correr, nadar y tocar la guitarra.
                     </p>
                 </>
                }

            </div>

            <div className="cc-screen-button">
                {/*<button className="d-none d-lg-inline btn btn-outline-primary me-2">*/}
                {/*    <Link*/}
                {/*        to="projects"*/}
                {/*        smooth={true}*/}
                {/*        offset={width >= 768 ? -75 : -290}*/}
                {/*        duration={scrollDuration()}*/}
                {/*    >*/}
                {/*        <div className="d-flex align-items-center">*/}
                {/*            <FontAwesomeIcon icon={faCode} className="fa-xs me-1"/>*/}
                {/*            {language.english && 'Projects'}*/}
                {/*            {!language.english && 'Proyectos'}*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</button>*/}

                {/*<button className="d-none d-lg-inline btn btn-outline-primary me-2">*/}
                {/*    <Link*/}
                {/*        to="skills"*/}
                {/*        smooth={true}*/}
                {/*        offset={width >= 768 ? -75 : -290}*/}
                {/*        duration={scrollDuration()}*/}
                {/*    >*/}
                {/*        <div className="d-flex align-items-center">*/}
                {/*            <FontAwesomeIcon icon={faMicrochip} className="fa-xs me-1"/>*/}
                {/*            {language.english && 'Skills'}*/}
                {/*            {!language.english && 'Tecnologías'}*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</button>*/}

                {/*<button className={`btn btn-outline-primary me-2 ${language.english ? 'cc-width-button-en' : 'cc-width-button-es'}`}>*/}
                {/*    <Link*/}
                {/*        to="contact"*/}
                {/*        smooth={true}*/}
                {/*        offset={contactOffset(width)}*/}
                {/*        duration={scrollDuration()}*/}
                {/*    >*/}
                {/*        <div className="d-flex align-items-center justify-content-center">*/}
                {/*            <FontAwesomeIcon icon={faEnvelope} className="cc-icon-size me-1"/>*/}
                {/*            {language.english && 'Contact'}*/}
                {/*            {!language.english && 'Contacto'}*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</button>*/}

                {/*<button className={`btn btn-outline-secondary ${width >= 992 ? 'me-2'*/}
                {/*                                                             : ''} ${language.english*/}
                {/*                                                                     ? 'cc-width-button-en'*/}
                {/*                                                                     : 'cc-width-button-es'}`}*/}
                {/*        onClick={() => goTo('linkedin')}>*/}
                {/*    <div className="d-flex align-items-center justify-content-center">*/}
                {/*        <FontAwesomeIcon icon={faLinkedin} className="me-1"/>LinkedIn*/}
                {/*    </div>*/}
                {/*</button>*/}

                <button className={`btn btn-outline-secondary ${width >= 992 ? 'me-2' : ''}`}
                        onClick={() => goTo('linkedin')}>
                    <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faLinkedin} className="me-1"/>LinkedIn
                    </div>
                </button>

                <button className="d-none d-lg-inline btn btn-outline-secondary me-2"
                        onClick={() => goTo('github')}>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faGithub} className="me-1"/>GitHub
                    </div>
                </button>
            </div>

        </div>
    )

}

export default About;