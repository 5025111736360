import React from 'react';
import {useSelector} from "react-redux";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import Line from "../Line";
import './index.css';
import projects from './projects.json';

const Projects = () => {

    const language = useSelector((state) => state);

    return (
        <div id="projects" className="container mt-5">

            <Line icon={faCode} section="Projects"/>

            <div className="mt-2">

                <div className="row">
                    {projects.map((p) => {
                        return <div className="col-lg-6 d-flex justify-content-center"
                                    key={p.title}>
                            <div className="card override-projects bg-light border-0 mb-5">
                                <div className="cc-img-wrapper">
                                    <img className="card-img override-projects" src={p.image}
                                         alt="minishell"/>
                                    <div className="cc-img-overlay">
                                        <div className="cc-text-overlay">
                                            {
                                                language.english &&
                                                p.descEN
                                            }
                                            {
                                                !language.english &&
                                                p.descES
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <h6 className="card-subtitle text-black-50">{p.title}</h6>
                                    <p className="card-text">
                                            <span className="fw-bold">
                                                {
                                                    language.english &&
                                                    'Tech: '
                                                }
                                                {
                                                    !language.english &&
                                                    'Tecno: '
                                                }
                                            </span>
                                        {p.tech}
                                    </p>
                                </div>
                            </div>
                        </div>
                    })}

                </div>

            </div>
        </div>
    );
}

export default Projects;