import React from 'react';
import {useSelector} from "react-redux";
import {animateScroll as scroll} from 'react-scroll';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {scrollDuration} from "../../utils";
import './index.css';

const Footer = () => {

    const language = useSelector((state) => state);

    return (
        <div className="bg-secondary mt-5">
            <div className="container">
                <div className="d-flex flex-column align-items-center p-3">
                    <div
                        className="cc-arrow d-flex align-items-center mb-3"
                        onClick={() => scroll.scrollToTop(
                            {smooth: true, duration: scrollDuration()})}
                    >
                        <FontAwesomeIcon icon={faArrowUp}/>
                        <span className="ms-1 fw-bold">
                            {language.english && 'TOP'}
                            {!language.english && 'ARRIBA'}
                        </span>
                    </div>
                    <img src="images/profile.jpeg" className="cc-profile-picture mb-3"
                         alt="profile"/>
                    <div className="text-white-50">Juan Ceruli</div>
                    <div className="text-white-50">Boston, MA · {new Date().getFullYear()}</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;