import {isSafari, isIOS} from "react-device-detect";

export const contactOffset = (width) => {
    if (width < 992) {
        return -300;
    } else if (width < 1024) {
        return -160;
    } else if (width < 1200) {
        return -670;
    } else if (width < 1400) {
        return -220;
    } else {
        return -160;
    }
}

export const scrollDuration = () => {
    if (isSafari || isIOS) {
        return 750;
    }
    return 0;
}