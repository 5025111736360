import './App.css';
import {createStore} from "redux";
import {Provider} from "react-redux";
import langReducer from "./reducers/langReducer";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const store = createStore(langReducer);

const App = () => {

    return (
        <Provider store={store}>
            <Navbar/>

            <About/>

            <Projects />

            <Skills />

            <Contact />

            <Footer/>
        </Provider>
    );
}

export default App;
